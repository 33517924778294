import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchLocalPipe } from './search-local.pipe';

@NgModule({
	declarations: [SearchLocalPipe],
	imports: [CommonModule],
	exports: [SearchLocalPipe],
})
export class SearchLocalModule {}
