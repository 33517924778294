import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { CutStringModule } from 'src/app/shared/pipes/cut-string/cut-string.module';

@Component({
	selector: 'box-messages',
	standalone: true,
	imports: [CommonModule, CutStringModule],
	templateUrl: './box-messages.component.html',
	styleUrls: ['./box-messages.component.scss'],
})
export class BoxMessagesComponent implements OnInit {
	@Input() dataSource: any[] = [];
	storageUri: string = environment.storage + '/tk/';
	constructor() {}

	ngOnInit(): void {}
}
