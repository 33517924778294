import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cutString',
})
export class CutStringPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		const mode = args.shift();

		if (mode === 'only-initial' && typeof value === 'string') {
			return value
				.split(' ')
				.map((str) => str.charAt(0))
				.join('');
		}

		console.log(value);
		console.log(args);

		return null;
	}
}
