import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { TitleCasePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { VlLoaderModule } from '@components/vl-loader/vl-loader.module';
import { WithThesePermissionModule } from '@directives/with-these-permission/with-these-permission.module';
import { VlNotificationModule } from '@components/vl-notification/vl-notification.module';
import { NgChartsModule } from 'ng2-charts';
import { VlModalModule } from '@components/vl-modal/vl-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		VlLoaderModule,
		WithThesePermissionModule,
		VlNotificationModule,
		NgChartsModule,
		VlModalModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
		TitleCasePipe,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
