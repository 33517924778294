<ng-container *ngFor="let msg of dataSource">
	<div class="boxMsg">
		<div class="boxMsg__header">
			<div [ngClass]="['boxMsg__header__avatar', !msg.agent ? 'client' : '']">
				{{ msg.agent?.fullName || msg.client?.name | cutString : 'only-initial' }}
			</div>
			<div class="boxMsg__header__sender">
				<p class="boxMsg__header__sender__name" *ngIf="msg.agent">{{ msg?.agent?.fullName | titlecase }} ({{ msg?.agent?.email }})</p>
				<p class="boxMsg__header__sender__name" *ngIf="!msg.agent">{{ msg?.client?.name | titlecase }}</p>
				<p class="boxMsg__header__sender__role">{{ msg.agent ? 'Agente' : 'Cliente' }}</p>
			</div>
			<div class="boxMsg__header__date">{{ msg?.date | date : 'dd/MM/YYYY HH:mm:ss' }}</div>
		</div>
		<div class="boxMsg__body">
			<p class="boxMsg__body__text">{{ msg.text }}</p>
			<ul class="boxMsg__body__files" *ngFor="let file of msg.files">
				<li class="boxMsg__body__files__item">
					<a class="boxMsg__body__files__item__file" [href]="storageUri + file._id" target="_blank">
						<span class="boxMsg__body__files__item__file__name">{{ file.originalname }}</span>
						<svg class="boxMsg__body__files__item__file__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
							<path
								d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"
								fill="currentColor"
							/>
						</svg>
					</a>
				</li>
			</ul>
		</div>
	</div>
</ng-container>

<p class="notDataText" *ngIf="dataSource.length === 0">No hay información disponible de esta sección en este ticket</p>
