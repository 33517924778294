// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiServer: 'https://api.dev.tk.omniflou.com',
	adminUrl: 'https://admin.tk.dev.omniflou.com',
	agentUrl: 'https://agent.tk.dev.omniflou.com',
	easyUrl: 'https://easy.tk.dev.omniflou.com/conditions',
	wsServer: 'https://ws.dev.tk.omniflou.com/videolink',
	storage: 'https://cdn.dev.tk.in.omniflou.com',
	vlTokenSupport: 'da82e69d186fca4bfb9a67f01d4723b9691f34752ba3a98ea49208ace5820661',
	urlCdnContent: 'https://cdn.tk.dev.omniflou.com/autoload.js',
	cdnFiles: 'https://cdn.tk.dev.omniflou.com',
	cdnWidget: 'https://widget.tk.dev.omniflou.com',
	mode: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
