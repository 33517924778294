import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'hk-vl-select',
	templateUrl: './hk-vl-select.component.html',
	styleUrls: ['./hk-vl-select.component.scss'],
})
export class HkVlSelectComponent implements OnChanges {
	@ViewChild('selectCustom', { static: true })
	selectCustom!: ElementRef<HTMLDivElement>;

	@HostListener('window:click', ['$event']) windonOnclick($event: any) {
		if (this.open && this.selectCustom?.nativeElement && !this.selectCustom.nativeElement.contains($event.composedPath()[0])) {
			this.toggleBoxOptions();
		}
	}

	@Input() withSearch: boolean = true;
	@Input() open: boolean = false;
	@Input() value: string | number = '';
	@Input() options: Array<{
		label: string | number;
		value: string | number;
		disabled?: boolean;
		item?: any;
	}> = [];

	labels: { [key: string]: string | number } = {};

	@Input() customMsgNotValue: string = 'Seleccionar';
	@Input() customMsgNotOptions: string | undefined;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Input() colorbgHoverSelect: string = '#eff1fd';
	@Input() colorTextHoverSelect: string = 'var(--black-2)';

	search = new FormControl('');
	subs: Subscription[] = [];

	coordenate = { x: 0, y: 0 };

	constructor() {}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		const sub = this.search.valueChanges.subscribe((value) => {
			this.calculateDimensions();
		});

		this.subs.push(sub);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']?.currentValue !== changes['value']?.previousValue) {
			this.buildLabels();
		}
		if (changes['options']?.currentValue !== changes['options']?.previousValue) {
			this.buildLabels();
		}
	}
	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.subs.forEach((sub) => sub.unsubscribe());
	}
	toggleBoxOptions() {
		const newValue = !this.open;

		if (newValue) {
			this.calculateDimensions();
		}

		this.open = newValue;

		if (!this.open) {
			this.onChange.emit(this.value);
			this.search.setValue('');
		}
	}

	calculateDimensions() {
		const coordenateSelect = this.selectCustom.nativeElement.getBoundingClientRect();
		const hookContainer = document.documentElement;

		const _options =
			(this.search.value ?? '').length > 0
				? this.options.filter((o: any) => o.label.toLowerCase().includes((this.search.value ?? '').toLowerCase()))
				: this.options;

		const boxOptions = _options.length * 43 > 240 ? 240 : _options.length * 43;

		if (coordenateSelect.y + boxOptions < hookContainer?.getBoundingClientRect().height + hookContainer?.getBoundingClientRect().y) {
			this.coordenate.y = 48;
		} else {
			this.coordenate.y = -boxOptions - 30;
		}
	}

	toggleOptions(option: { label: string | number; value: string | number; disabled?: boolean; item?: any }) {
		this.value = option.value;
		this.toggleBoxOptions();
	}

	buildLabels() {
		this.labels = {};
		this.options.forEach((option) => {
			this.labels[option.value] = option.label;
		});
	}
}
