import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'searchLocal',
})
export class SearchLocalPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		const [search] = args;

		return search.length > 0 ? (value = value.filter((o: any) => o.label.toLowerCase().includes(search.toLowerCase()))) : value;
	}
}
