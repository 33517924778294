import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HkVlSelectComponent } from './hk-vl-select.component';
import { SearchLocalModule } from '../../pipes/search-local/search-local.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [HkVlSelectComponent],
	imports: [CommonModule, SearchLocalModule, ReactiveFormsModule],
	exports: [HkVlSelectComponent],
})
export class HkVlSelectModule {}
