import { Component, OnDestroy, HostListener, ViewChild, ElementRef, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalService } from '@services/global/modal.service';
import { NotificationService } from '@services/global/notification.service';
import { getErrorFormMsg } from '@utils/getErrorMsgForm';
import { isEqual } from 'lodash';
import { Subject, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs';

@Component({
	selector: 'vl-modal',
	templateUrl: './vl-modal.component.html',
	styleUrls: ['./vl-modal.component.scss'],
})
export class VlModalComponent implements OnDestroy {
	isOpen: boolean = false;
	config: any = {};
	element: any = {};
	keys = Object.keys;
	validAllForm = false;
	private _unSuscribeAll: Subject<any> = new Subject();
	private _unSuscribeChange: Subject<any> = new Subject();
	@ViewChild('dialog', { static: true })
	dialog!: ElementRef<HTMLDivElement>;
	@HostListener('window:click', ['$event']) windonOnclick($event: any) {
		let calendarMaterial = document.querySelector('mat-calendar');

		if (
			this.isOpen &&
			this.dialog?.nativeElement &&
			!this.dialog.nativeElement.contains($event.composedPath()[0]) &&
			!calendarMaterial &&
			!$event.composedPath().includes(this.dialog.nativeElement)
		) {
			this.hidden();
		}
	}
	@HostListener('document:keydown.escape', ['$event']) windonOnEsc($event: any) {
		if (this.isOpen && this.dialog?.nativeElement && !this.dialog.nativeElement.contains($event.composedPath()[0])) {
			this.hidden();
		}
	}

	message: string = '';
	files: Array<any> = [];
	notifiationSrc: NotificationService = inject(NotificationService);

	constructor(private _modalSrc: ModalService) {
		this._modalSrc.onChange.pipe(takeUntil(this._unSuscribeChange)).subscribe(({ element, config, isOpen }) => {
			this.element = element;
			this.config = config;

			if (this.isOpen && !isOpen) {
				this._unSuscribeAll.next(true);
				this._unSuscribeAll.complete();
				this._unSuscribeAll = new Subject();
			}

			this.isOpen = isOpen;

			this.checkForms();
		});
	}

	setOnChangesFormControls() {
		const allForm = this.config?.body?.content.filter(({ type }: any) => type === 'form');

		allForm.forEach(({ item }: any) => {
			const formG = item.value;

			const formC = Object.entries(item.configControls).filter(([key, value]: any) => Boolean(value.onChange));

			formC.forEach(([key, value]: any) => {
				formG.controls[key]?.valueChanges
					.pipe(takeUntil(this._unSuscribeAll), debounceTime(1000), distinctUntilChanged(isEqual))
					.subscribe((controlValue: any) => {
						value.onChange();
					});
			});
		});
	}

	checkForms() {
		const allForm = this.config?.body?.content.filter(({ type }: any) => type === 'form');

		if (allForm.length === 0) {
			this.validAllForm = true;
			return;
		} else {
			this.validAllForm = this.config?.body?.content
				.filter(({ type }: any) => type === 'form')
				.every(({ item }: any) => item.value.valid === true);
		}

		allForm.forEach(({ item }: any) => {
			item.value.valueChanges.pipe(takeUntil(this._unSuscribeAll)).subscribe(() => {
				this.validAllForm = this.config?.body?.content
					.filter(({ type }: any) => type === 'form')
					.every(({ item }: any) => item.value.valid === true);
			});
		});

		this.setOnChangesFormControls();
	}

	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
		this._unSuscribeChange.next({});
		this._unSuscribeChange.complete();
	}

	hidden(element = null) {
		let _element: any = element;

		if (element != null && this.config?.body?.content.filter(({ type }: any) => type === 'form').length > 0) {
			_element = { ...(element as any), FORMS_RESPONSE: {} };

			this.config?.body?.content
				.filter(({ type }: any) => type === 'form')
				.forEach(({ item }: any) => {
					const { formName, value: form } = item;

					Object.keys(form.controls).forEach((key) => {
						form.controls[key].enable();
					});

					_element.FORMS_RESPONSE[formName] = form.value;
				});
		}

		this._modalSrc.close(_element);
		this.message = '';
		this.files = [];
	}

	resetForms(element = null) {
		this.config?.body?.content
			.filter(({ type }: any) => type === 'form')
			.forEach(({ item }: any) => {
				Object.keys(item.value.value).forEach((key) => {
					if (!item.toReset[key]) return;
					item.value.controls[key].reset(item.toReset[key]);
				});
			});

		this.hidden(element);
	}

	getErrorMessages(formRef: FormGroup, keyForm: string, optionals?: object) {
		return getErrorFormMsg({ formRef: formRef, keyForm, ...optionals });
	}

	removeFile(index: number) {
		this.files.splice(index, 1);
	}

	async changeFiles(event: any) {
		const files = event.target.files;

		if (files.length + this.files.length > 10)
			return this.notifiationSrc.show({ type: 'error', msg: 'No se pueden subir mas de 10 archivos' });

		if (files.length) {
			Array(...files).forEach((element: File) => {
				this.files.push(element);
			});
		}
	}

	sendMessage() {
		this._modalSrc.close({
			...this.element,
			RESPONSE: {
				message: this.message,
				files: this.files,
			},
		} as any);

		this.message = '';
		this.files = [];
	}
}
