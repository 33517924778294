import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutStringPipe } from './cut-string.pipe';

@NgModule({
	declarations: [CutStringPipe],
	imports: [CommonModule],
	exports: [CutStringPipe],
})
export class CutStringModule {}
