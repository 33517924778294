import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlModalComponent } from './vl-modal.component';
import { VlIconModule } from '@components/vl-icon/vl-icon.module';
import { VlFormGroupModule } from '@components/vl-form-group/vl-form-group.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BoxMessagesComponent } from '@components/vl-wizard/components/box-messages/box-messages.component';

@NgModule({
	declarations: [VlModalComponent],
	imports: [CommonModule, VlIconModule, VlFormGroupModule, BoxMessagesComponent],
	exports: [VlModalComponent],
})
export class VlModalModule {}
